import { ExperimentPaymentPlanVariant } from '@customer-frontend/graphql-types';
import { calculatePaymentPlanChargeAmount } from './calculate-experiment-payment-plan-charge-amount';
import { CartLineItem } from '../line-items';

export const calculatePaymentPlanChargeAmountFromLineItems = ({
  lineItems,
  experimentPaymentPlan,
}: {
  lineItems: CartLineItem[];
  experimentPaymentPlan: {
    paymentPlan: ExperimentPaymentPlanVariant;
    discountPercentage: number;
  };
}): { totalPrice: number; amountSaved: number; perOrder: number } =>
  calculatePaymentPlanChargeAmount({
    recurringCharge: lineItems.reduce(
      (acc, item) =>
        item.isRecurring || item.productType === 'RX'
          ? acc + (item.price ?? 0)
          : acc,
      0,
    ),
    oneTimeCharge: lineItems.reduce(
      (acc, item) =>
        item.isRecurring || item.productType === 'RX'
          ? acc
          : acc + (item.price ?? 0),
      0,
    ),
    experimentPaymentPlan,
  });
