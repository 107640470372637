import { EnvironmentProvider } from '@customer-frontend/environment';
import { EventServiceProvider } from '@customer-frontend/events';
import { FeatureFlagProvider } from '@customer-frontend/feature-flags';
import { notificationService } from '@customer-frontend/notifications';
import { OfflineWrapper } from '@customer-frontend/offline';
import { utmService } from '@customer-frontend/utm';
import { I18nProvider } from '@customer-frontend/i18n';
import { getConfig } from '@customer-frontend/config';
import {
  DesignSystemProvider,
  LoadingSpinner,
} from '@eucalyptusvc/design-system';
import PilotPlus from 'assets/illustrations/pilot-plus.svg';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ClientProvider } from '@customer-frontend/graphql-client';
import { AuthProvider, authService } from 'services/auth';
import { eventService } from 'services/event';
import { environment } from 'utils/environment';
import { logger } from 'utils/logging';
import { routes } from 'utils/routes';
import { ChatProvider } from '@customer-frontend/chat-provider';

import 'styles/tailwind.generated.css';
import 'styles/chat.css';
import './utils/rum';
import { ErrorBoundary } from '@customer-frontend/services';

const Pages = React.lazy(() => import('./pages'));

const config = getConfig();

notificationService.init({ brand: config.brand });
utmService.saveUtmsToCookie();

const App = (): React.ReactElement => {
  return (
    <EnvironmentProvider {...environment}>
      <DesignSystemProvider brand={config.brand}>
        <I18nProvider>
          <ClientProvider
            apiUrl={environment.apiUrl}
            appEnv={environment.appEnv}
            logger={logger}
            getToken={() => authService.getAccessToken()}
            onUnauthenticated={() => {
              authService.clear();
              window.location.href = routes.login;
            }}
          >
            <AuthProvider logger={logger}>
              <EventServiceProvider service={eventService}>
                <FeatureFlagProvider logger={logger}>
                  <ChatProvider
                    streamAppKey={environment.streamAppKey || ''}
                    logger={logger}
                  >
                    <OfflineWrapper brandName="Pilot" image={PilotPlus}>
                      <ErrorBoundary
                        profileRoute={routes.profile}
                        palette={{
                          messageColor: 'white',
                          refreshButton: 'alternate',
                          profileButton: 'white',
                        }}
                        environment={environment}
                        logger={logger}
                      >
                        <React.Suspense
                          fallback={
                            <div className="flex justify-center p-5">
                              <LoadingSpinner />
                            </div>
                          }
                        >
                          <Pages />
                        </React.Suspense>
                      </ErrorBoundary>
                    </OfflineWrapper>
                  </ChatProvider>
                </FeatureFlagProvider>
              </EventServiceProvider>
            </AuthProvider>
          </ClientProvider>
        </I18nProvider>
      </DesignSystemProvider>
    </EnvironmentProvider>
  );
};

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(rootElement);
root.render(<App />);
